import React, { useState } from "react";
// import { useMutation } from "@apollo/react-hooks";
import { Spin } from "antd";
// import { ADMIN_SIGNUP } from "../graphql/modules";
// import { successNotify, warnNotify } from "../util";
import { CustomButton } from "../components/Shared";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { ADMIN_SIGNUP } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";

export const SignUp = ({ history, ...props }) => {
  //initial state
  const [state, setState] = useState({
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    instagramProfile: "",
    cpassword: "",
  });

  const [error, setError] = useState(null);

  const onBlurPassword = () => {
    if (state.password.length < 6) {
      setError({
        ...error,
        password: "Password must be more than 6 character",
      });
    } else {
      setError({ ...error, password: "" });
    }
  };

  const onBlurCPassword = () => {
    if (state.password !== state.cpassword) {
      setError({
        ...error,
        cpassword: "Password and confirm password mismatch",
      });
    } else {
      setError({ ...error, cpassword: "" });
    }
  };

  // const { cpassword, ...regData } = state;
  // register api
  const [RegisterMutation, { loading }] = useMutation(ADMIN_SIGNUP);

  const onRegister = async (e) => {
    e.preventDefault();
    delete state.cpassword;
    try {
      const {
        data: { AdminRegister },
      } = await RegisterMutation({
        variables: {
          userInput: state,
        },
      });
      if (AdminRegister.success) {
        successNotify(AdminRegister.message);
        history.push("/signin");
      } else {
        warnNotify(AdminRegister.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const disable =
    !state.email ||
    !state.password ||
    !state.firstname ||
    !state.lastname ||
    !state.instagramProfile ||
    state.password.length < 6 ||
    state.password !== state.cpassword;

  return (
    <div className="col-md-8 m-auto">
      <div className="card" style={{ width: "100%", margin: "auto" }}>
        <Spin spinning={false}>
          <div className="login-form">
            <h1 className="title">Sign Up</h1>
            <form onSubmit={onRegister}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="firstname"
                    value={state.firstname}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="lastname"
                    value={state.lastname}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Instagram Profile Link"
                    name="instagramProfile"
                    value={state.instagramProfile}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={state.email}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={state.password}
                    onChange={onChange}
                    onBlur={onBlurPassword}
                  />
                  {error?.password && (
                    <small className="text-danger">{error?.password}</small>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    name="cpassword"
                    value={state.cpassword}
                    onChange={onChange}
                    onBlur={onBlurCPassword}
                  />
                  {error?.cpassword && (
                    <small className="text-danger">{error?.cpassword}</small>
                  )}
                </div>
              </div>
              {/* <div className="form-row">
                <div className="form-group col-md-6">
                  <select id="inputState" className="form-control" name="site" value={state.site} onChange={onChange}>
                    <option value="">Site Selection</option>
                    {data?.GetSites?.results?.map((site) => (
                      <option key={site._id} value={site._id}>
                        {site.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <input type="text" className="form-control" placeholder="Company Name" />
                </div>
              </div> */}
              <div className="form-group">
                <CustomButton
                  type="submit"
                  disabled={disable}
                  loading={loading}
                  className="btn w-full btn-primary rounded-0"
                  onClick={onRegister}
                >
                  Sign Up
                </CustomButton>
              </div>
            </form>
            <Link
              to="/signin"
              style={{
                color: "#3876e3",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              Already have account. Signin
            </Link>
          </div>
        </Spin>
      </div>
    </div>
  );
};
